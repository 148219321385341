<template>
  <v-container fluid>
    <h3>รายงานจำนวนด่านจราจรประจำวัน</h3>
    <div class="mt-5">
      <v-row>
        <v-col cols="12" md="6">
          <v-card>
            <v-card-title>ข้อมูลการตั้งด่านกวดขันวินัยจราจร</v-card-title>
            <v-card-text>
              <!-- Content for the card goes here -->
              <v-btn color="indigo darken-2" @click="exportTrafficReportClick" class="white--text">
                <v-icon small>
                  mdi-file-excel
                </v-icon>
                ดาวน์โหลด Excel
              </v-btn>
              <!-- <p style="cursor: pointer; color: brown; font-size: 1.2rem;">ดาวน์โหลดข้อมูลการตั้งด่านกวดขัยวินัยจราจร</p> -->
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card>
            <v-card-title>ข้อมูลการตั้งด่านตรวจวัดแอลกอฮอล์</v-card-title>
            <v-card-text>
              <!-- Content for the card goes here -->
              <v-btn color="indigo darken-2" @click="exportAlcoholReportClick" class="white--text">
                <v-icon small>
                  mdi-file-excel
                </v-icon>
                ดาวน์โหลด Excel
              </v-btn>
              <!-- <p style="cursor: pointer; color: brown; font-size: 1.2rem;">ดาวน์โหลดข้อมูลการตั้งด่านกวดขัยวินัยจราจร</p> -->
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

    </div>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { exportTrafficReport, exportAlcoholReport } from '@/api/report'

export default {
  methods: {
    exportTrafficReportClick() {
      this.loading = true
      exportTrafficReport()
        .then(response => {
          this.loading = false

        })
    },
    exportAlcoholReportClick() {
      this.loading = true
      exportAlcoholReport()
        .then(response => {
          this.loading = false

        })
    },
  },
  data() {
    return {
      loading: false,
    }
  },
  watch: {},
  components: {
  },
  computed: {
    ...mapGetters('Appfilter', [
      'bureauId',
      'divisionId',
      'dateStart',
      'dateEnd',
    ]),
    ...mapGetters('User', ['name', 'position', 'role', 'avatarPicture', 'station', 'phone', 'level_name']),

  },
}
</script>

<style></style>
