import http from './http'

export function exportTrafficReport(params) {
  return http({
    url: `/api/v2/all-traffic-today`,
    method: 'get',
    params: params
  }, { responseType: 'blob' })
    .then(res => {
      const url = window.URL.createObjectURL(new Blob([res]));
      const a = document.createElement("a");
      a.href = url;
      const today = new Date();
      const date = `${today.getDate().toString().padStart(2, '0')}_${(today.getMonth() + 1).toString().padStart(2, '0')}_${today.getFullYear() + 543}_${today.getHours().toString().padStart(2, '0')}_${today.getMinutes().toString().padStart(2, '0')}`;
      const filename = `report_all_traffic_${date}.csv`;
      a.setAttribute('download', filename);
      a.click();
      a.remove();
    })
}

export function exportAlcoholReport(params) {
  return http({
    url: `/api/v2/all-alcohol-today`,
    method: 'get',
    params: params
  }, { responseType: 'blob' })
    .then(res => {
      const url = window.URL.createObjectURL(new Blob([res]));
      const a = document.createElement("a");
      a.href = url;
      const today = new Date();
      const date = `${today.getDate().toString().padStart(2, '0')}_${(today.getMonth() + 1).toString().padStart(2, '0')}_${today.getFullYear() + 543}_${today.getHours().toString().padStart(2, '0')}_${today.getMinutes().toString().padStart(2, '0')}`;
      const filename = `report_all_alcohol_${date}.csv`;
      a.setAttribute('download', filename);
      a.click();
      a.remove();
    })
}


